import React, { useContext, useEffect, useState } from 'react';
import { AccountContext } from './Account';
import { Outlet, Link, Navigate } from 'react-router-dom';
export default function PrivateRoute(props) {
  const {
    getSession,
    logout,
    status,
    setStatus,
    setApiKey,
    apiKey,
    setUser_Id,
    user_id,
  } = useContext(AccountContext);

  useEffect(() => {
    getSession().then((session) => {
      console.log('Private roue Session: ', session);
      console.log('private api : ', apiKey, 'private userid', user_id);
    });
  }, []);
  if (!status) {
    return <Navigate to='/login' />;
  }
  return props.children;
}
