import React, {
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import MaterialReactTable from "material-react-table";
import QRCode from "react-qr-code";
import { AccountContext } from "../Account";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  ListItemIcon,
  Modal,
} from "@mui/material";
import {
  Delete,
  Edit,
  OpenInNew,
  Download,
  Group,
  Done,
} from "@mui/icons-material";

const Dlr = () => {
  const { apiKey, user_id, userMail } = useContext(AccountContext);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [devices, setDevices] = useState([]);
  const [lastData, setLastData] = useState();

  const [validationErrors, setValidationErrors] = useState({});
  const [postdata, setpostdata] = useState(false);
  const [loading, setLoading] = useState(true);
  const [groupName, setGroupName] = useState("");
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState(false);
  const [connection, setConnection] = useState(3);
const [newdata, setnewdata] = useState(false);
  var demolast;
  const navigate = useNavigate();
useEffect( () => {
  var tempdevices =[]
  var tempTable = [];
  const fetchData = async () => {
    try {
      setLoading(true);

      const myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };

      const response = await fetch(
        "https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci",
        requestOptions
      );
      const result = await response.json();

      console.log("first fetch", result);
      const filteredResult = result?.body?.filter(
        (obj) => !obj.deleted_at && obj.type === "dlr"
      );

      const sortedResult = filteredResult?.sort((a, b) => b.produced_at - a.produced_at);
      var tempdeviceids=[];
      filteredResult?.map(e => tempdeviceids.push(e.id))
      setDevices(prev => tempdeviceids)
      tempdevices = tempdeviceids

      console.log("tempdevices",tempdevices);
      console.log("tempdeviceids",tempdeviceids);
      

      if (sortedResult) {
        for (const [index, e] of sortedResult.entries()) {
          const responseLast = await fetch(
            `https://g0vud29n03.execute-api.eu-west-1.amazonaws.com/Stage1?id=${e.id}&type=dlrlast&izcilength=60`,
            requestOptions
          );
          const resultLast = await responseLast.json();
          const body = JSON.parse(resultLast.body);
      
          console.log("last", body);
          e.line_current = body.calibrated_adc_line_current;
          e.digital_line_current = body.adc_line_current;
          e.last_measure = body.received_date;
          e.seq = body.izci_seq;
            
          tempTable.push({ ...e });
      
          if (index === sortedResult.length - 1) {
            console.log("bakalım", index, sortedResult.length - 1);
            demolast = sortedResult;
            console.log("21bakalm", sortedResult);
          }
        }
      }
      

      console.log("tempTable", tempTable);
      setTableData(prev => tempTable);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };

 const fetchDataAndWebsocket = async ()=>{
  try {
    await fetchData()
    // WebSocket bağlantısını oluşturun
    var socket = new WebSocket(`wss://q77dwm2wr3.execute-api.eu-west-1.amazonaws.com/production/?user_id=${user_id}&devices=[${tempdevices}]`);
  
    // Bağlantı başarıyla kurulduğunda
    socket.addEventListener('open', (event) => {
      console.log('WebSocket bağlantısı başarıyla kuruldu.', event);
    });
    const pongInterval = setInterval(() => {
      socket.send('ping');
    }, 30000); // Her 30 saniyede bir
    // Mesaj alındığında
    socket.addEventListener('message', (event) => {
      setnewdata(prev => true)
      console.log('WebSocketten gelen mesaj:', event);
      var dat = JSON.parse(event.data)
      var parseddata = {...dat.data, ...dat.data.izci_packet}
      console.log('dat dat dat:', parseddata);
      console.log('tempTable:', tempTable);
  
      const updatedTemTable = tempTable.map(item => {
        if (item.id === parseddata.izci_id) {
          console.log("bulundu ve güncelledi",{
            ...item,
            // Diğer özellikler...
          });
          return {
            ...item,
            line_current:parseddata.calibrated_adc_line_current,
            digital_line_current:parseddata.adc_line_current,
            last_measure: parseddata.received_date,
            seq:parseddata.izci_seq
          };
        }
        return item; // Eşleşmeyen diğer öğeleri olduğu gibi bırak
      });
      console.log("updatedTemTable",updatedTemTable);
      setLastData(prev => parseddata)
      setTableData(prev=> updatedTemTable)
      demolast = updatedTemTable
      tempTable = updatedTemTable
      
    });
  
    // Bağlantı kapandığında
    socket.addEventListener('close', (event) => {
      console.log('WebSocket bağlantısı kapatıldı.', event);
      clearInterval(pongInterval);
      
      setTimeout(() => {
        setConnection(prev => !prev)
      }, 5000);
    });
  
    // Hata oluştuğunda
    socket.addEventListener('error', (event) => {
      console.error('WebSocket hatası:', event);
    });
    console.log("websocet kapalı triggered",socket.readyState); 
  } catch (error) {
    console.log(error);
  }
 }
  fetchDataAndWebsocket()
}, [postdata]);
useEffect(() => {
  if (newdata) {
    // Animasyon bitiminde setNewData(false) işlemini gerçekleştir
    const timeoutId = setTimeout(() => {
      setnewdata(prev => false);
    }, 3000); // Animasyon süresini burada belirleyebilirsiniz (örnekte 1 saniye)
    
    // Temizlik işlemleri
    return () => clearTimeout(timeoutId);
  }
}, [newdata]);
function setLast(time) {
  var mdate = new Date(Number(time));
  mdate.setMinutes(mdate.getMinutes());

  time = mdate;
  return `       ${mdate.getHours()}:${
    mdate.getMinutes() < 10
      ? `0${mdate.getMinutes()}`
      : mdate.getMinutes()
  }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
  `;
}

  const handleCreateNewRow = (values) => {
    console.log("values: ", values);
    values.type = "dlr";
    values.izci_length = 60;
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    delete values['']
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(values),
    };

    fetch(
      "https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result))
      .then(setpostdata(!postdata))
      .catch((error) => console.log("error", error));
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var updatedValues = values;
      var id = values.id;
      delete updatedValues.id;
      delete updatedValues.produced_at;

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(values),
      };
      console.log(values);
      fetch(
        `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log("error", error));

      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row) => {
      //
      if (
        !window.confirm(`Are you sure you want to delete ${row.getValue("id")}`)
      ) {
        return;
      }
      console.log("deleted");
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var deletebody = { deleted_at: "12" };
      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        body: JSON.stringify(deletebody),
      };

      fetch(
        `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${row.getValue(
          "id"
        )}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log("error", error));
    },
    [tableData]
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );
  const colObj = userMail?.includes("@yt.com.tr")
    ? [
        {
          accessorFn: (originalRow) => {
            var selectedrow = demolast?.find((e) => e.id == originalRow.id);
            return <div className={``}>{selectedrow?.seq}</div>;
          },
          header: "seq",
          size: 80,
        },

        {
          accessorKey: "izci_hw_group",
          header: "hw_group",

          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: "izci_hw_cfg",
          header: "hw_cfg",
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: "izci_packet_ver",
          header: "packet_ver",
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: "izci_hw_ver",
          header: "hw_ver",
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: "izci_hw_rev",
          header: "hw_rev",
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: "izci_sw_ver",
          header: "sw_ver",
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: "produced_at",
          header: "produced_at",
          enableEditing: false, //disable editing on this column
          size: 80,
        },
        {
          accessorKey: "owner_id",
          header: "owner_id",
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: "qr",
          header: "qr",
          enableColumnOrdering: false,
          enableEditing: false, //disable editing on this column
          enableSorting: false,
          size: 80,
          Cell: ({ cell }) => (
            <>
              <QRCode
                id={cell.row.original.id}
                size={256}
                style={{
                  height: "auto",
                  width: "70px",
                }}
                value={cell.row.original.id}
                viewBox={`0 0 256 256`}
              />
            </>
          ),
        },
      ]
    : [];
  const columns = useMemo(
    () => [
      {
        accessorKey: "group_name",
        header: "group_name",

        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "id",
        header: "ID",
        enableColumnOrdering: true,
        enableEditing: true, //disable editing on this column
        enableSorting: true,
        size: 80,
        muiTableBodyCellProps: ({ cell }) => ({
          onClick: (event) => {
            navigate(`${cell.row.original.id}`);
            console.info(event, cell.row.original.id);
          },
          sx: {
            cursor: "pointer", //you might want to change the cursor too when adding an onClick
            color: "blue",
            textDecoration: "underline",
          },
        }),
      },

      {
        accessorFn: (originalRow) => {
          var selectedrow = demolast?.find((e) => e.id == originalRow.id);
          return <div className={``}>{selectedrow?.line_current}</div>;
        },
        header: "line_current",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var selectedrow = demolast?.find((e) => e.id == originalRow.id);
          return <div className={``}>{selectedrow?.digital_line_current}</div>;
        },
        header: "digital_line_current",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var selectedrow = demolast?.find((e) => e.id == originalRow.id);
          return (
            <div  >{formatDate(selectedrow?.last_measure)}</div>
          );
        },
        header: "last_measure",
        size: 80,
      },
      ...colObj,
    ],
    [getCommonEditTextFieldProps]
  );

  const downloadQRCode = (row) => {
    console.log("row : ", row.original.id);
    var svg = document.getElementById(row.original.id);

    //get svg source.
    var serializer = new XMLSerializer();
    var source = serializer.serializeToString(svg);

    //add name spaces.
    if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns="http://www.w3.org/2000/svg"'
      );
    }
    if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
      );
    }

    //add xml declaration
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

    //convert svg source to URI data scheme.
    var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);

    //set url value to a element's href attribute.

    //you can download svg file by right click menu.
    let downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${row.original.id}.svg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  function handleOpenInNew(row) {
    console.log(row.original.id);
    // navigate(row.original.id, '_blank');
    window.open(`/izci/${row.original.id}`, "_blank");
  }
  function handleOpenCalibration(row) {
    navigate(`${row.original.id}/calibration`);
  }
  function formatDate(date) {
    console.log("formatdate",typeof date);
    var mdate = new Date(Number(date));
    mdate.setMinutes(mdate.getMinutes());
    return `       ${mdate.getHours()}:${
      mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    }
     ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    `;
  }
  function fetchLast(data) {
    fetch(
      `https://g0vud29n03.execute-api.eu-west-1.amazonaws.com/Stage1?id=${data.id}&type=last&izcilength=${data.izci_length}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        const resultfilter = JSON.parse(result.body);
        Object.keys(resultfilter).map((e, i) => {
          data[e] = resultfilter[e];
        });
      })
      .catch((err) => {
        console.log("lasdatafetcherr : ", err);
      });
    const original = [
      "coords",
      "izci_hw_group",
      "izci_length",
      "qr",
      "created_at",
      "izci_packet_ver",
      "izci_hw_cfg",
      "izci_hw_rev",
      "izci_hw_ver",
      "owner_id",
      "produced_at",
      "izci_sw_ver",
      "id",
      "type",
      "time",
    ];
    return (
      <div className="flex flex-row  ">
        <p className="text-xl font-bold text-blue-600 mr-5 items-center">
          <p>last measure </p> <p>{formatDate(data.time)}</p>
        </p>
        {Object.keys(data).map((e, i) => {
          if (!original.includes(e)) {
            return (
              <div
                key={Math.random()}
                className="flex flex-col justify-center items-center bg-slate-200 "
              >
                <p className="text-lg font-bold text-gray-600 px-5">{e}</p>
                <p className="text-xl font-bold bg-white w-full px-5">
                  {Number(data[e]).toFixed(1)}
                </p>
              </div>
            );
          }
        })}
      </div>
    );
  }

  const handleSetGroup = async (data, groupName) => {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify({ group_name: groupName }),
    };

    data.map((e) => {
      fetch(
        `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${e.original.id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    });
    setIsGroupModal(false);

    // fetch(
    //   `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${id}`,
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => console.log(result))
    //   .then(setpostdata(!postdata))
    //   .catch((error) => console.log("error", error));
  };
  function getAll() {
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(
      "https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("noldubize: ", result);
        console.log(result.body.filter((obj) => !obj.deleted_at));
        const resultfilter = result.body.filter(
          (obj) => !obj.deleted_at && obj.type == "dlr"
        );

        const resultsort = resultfilter.sort(function (a, b) {
          return b.produced_at - a.produced_at;
        });

        console.log("resultsort : ", resultsort);
        setTableData(resultsort);
      })
      .then(() => setLoading(false))

      .catch((error) => console.log("error", error));
  }

  return (
    <>
      {!loading ? (
        <MaterialReactTable
          columns={columns}
          data={tableData}
          editingMode="modal" //default
          enableColumnOrdering
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          // renderDetailPanel={({ row }) => fetchLast(row.original)}
          enableRowNumbers={userMail?.includes("@yt.com.tr") ? true : false}
          enableRowActions={userMail?.includes("@yt.com.tr") ? true : false}
          enableRowSelection={userMail?.includes("@yt.com.tr") ? true : false}
          enableTopToolbar={userMail?.includes("@yt.com.tr") ? true : false}
          enableGrouping
          enableStickyHeader
          positionToolbarAlertBanner="bottom"
          initialState={{
            density: "compact",
            expanded: true,
            grouping: ["group_name"],
            pagination: { pageSize: 100, pageIndex: 0 },
          }}
          state={{
            isLoading: loading,
          }}
          renderRowActionMenuItems={({ closeMenu, table, row }) => [
            <MenuItem
              key={0}
              onClick={() => {
                table.setEditingRow(row);
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              Edit
            </MenuItem>,
            <MenuItem
              key={1}
              onClick={() => {
                handleDeleteRow(row);
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              Delete
            </MenuItem>,

            <MenuItem
              key={2}
              onClick={() => {
                downloadQRCode(row);
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Download />
              </ListItemIcon>
              Download QR Code
            </MenuItem>,
            // <MenuItem
            //   key={3}
            //   onClick={() => {
            //     handleOpenCalibration(row);
            //     closeMenu();
            //   }}
            //   sx={{ m: 0 }}
            // >
            //   <ListItemIcon>
            //     <OpenInNew />
            //   </ListItemIcon>
            //   Calibration
            // </MenuItem>,
          ]}
          renderTopToolbarCustomActions={({ table }) => (
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                p: "0.5rem",
                flexWrap: "wrap",
              }}
            >
              <Button
                color="primary"
                onClick={() => setCreateModalOpen(true)}
                variant="contained"
              >
                Create New İZCİ
              </Button>
              <Button
                disabled={
                  !table.getIsSomeRowsSelected() &&
                  !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() => {
                  setIsGroupModal(true);
                  setSelectedRows(table.getSelectedRowModel().rows);
                }}
                variant="contained"
              >
                Set Group
              </Button>
            </Box>
          )}
          muiTableBodyRowProps={({
            row
          }) => ({
            sx: {
              
              animation: newdata & row.original.id == lastData?.izci_id ? "blink 1s ":"",
              '@keyframes blink': {
                '0%': { backgroundColor: 'white' },
                '50%': { backgroundColor: '#fffd00' },
                '100%': { backgroundColor: 'white' },
              },
             
            }
          })}
        />
      ) : (
        <div className=" flex flex-row flex-wrap gap-5 ml-5 mr-5 ">
          <div className="bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10"></div>
        </div>
      )}

      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
      <Modal
        open={isGroupModal}
        onClose={() => setIsGroupModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3 bg-white rounded-md  shadow-24 p-4  flex flex-col">
          <p className="text-2xl w-full  text-center pt- pb-4">Group Name</p>
          <div className="flex flex-col gap-2">
            <TextField
              id="outlined-basic"
              label="Group Name"
              variant="standard"
              value={groupName}
              onChange={(event) => setGroupName(event.target.value)}
            />
          </div>
          <div className=" p-4 w-full flex flex-row gap-2 justify-end">
            <Button
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => setIsGroupModal(false)}
              variant="text"
            >
              Cancel
            </Button>
            <Button
              //  disabled={ }
              //export all rows, including from the next page, (still respects filtering and sorting)
              onClick={() => {
                handleSetGroup(selectedRows, groupName);
                getAll();
              }}
              startIcon={<Done />}
              variant="contained"
            >
              Apply
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {})
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign='center'>Create New Dlr</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {console.log("values : ",values)}
            {columns.map((column) =>
              column.header == 'group_name' || column.header == 'ID'   ? (
                <TextField
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  onChange={(e) =>
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }
                />
              ) : (
                ''
              )
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color='primary' onClick={handleSubmit} variant='contained'>
          Create New Dlr
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value) => !!value.length;

export default Dlr;
