import React from 'react';

import { Outlet, Link, useNavigate } from 'react-router-dom';

const IzciLayout = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default IzciLayout;
