import React, {
    useMemo,
    useEffect,
    useContext,
    useCallback,
    useState,
  } from 'react';
  import { useNavigate } from 'react-router-dom';
  import MaterialReactTable from 'material-react-table';
  import QRCode from 'react-qr-code';
  import { AccountContext } from '../Account';
  import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    MenuItem,
    Stack,
    TextField,
    Tooltip,
    ListItemIcon,
  } from '@mui/material';
  import { Delete, Edit, OpenInNew, Download } from '@mui/icons-material';
  
  const ToF = () => {
    const { apiKey, user_id } = useContext(AccountContext);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [postdata, setpostdata] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
      var myHeaders = new Headers();
      myHeaders.append('apikey', apiKey);
      myHeaders.append('userid', user_id);
  
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
      };
  
      fetch(
        'https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci',
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log('noldubize: ', result);
          console.log(result.body.filter((obj) => !obj.deleted_at));
          const resultfilter = result.body.filter((obj) => !obj.deleted_at && obj.type == 'tof');
  
          const resultsort = resultfilter.sort(function (a, b) {
            return b.produced_at - a.produced_at;
          });
  
          console.log('resultsort : ', resultsort);
          setTableData(resultsort);
        }).then(()=>setLoading(false))
  
        .catch((error) => console.log('error', error));
    }, [postdata]);
  
    const handleCreateNewRow = (values) => {
      console.log('values: ', values);
      values.type = 'tof';
      var myHeaders = new Headers();
      myHeaders.append('apikey', apiKey);
      myHeaders.append('userid', user_id);
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify(values),
      };
  
      fetch(
        'https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci',
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log('error', error));
    };
  
    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        var myHeaders = new Headers();
        myHeaders.append('apikey', apiKey);
        myHeaders.append('userid', user_id);
        var updatedValues = values;
        var id = values.id;
        delete updatedValues.id;
        delete updatedValues.produced_at;
  
        var requestOptions = {
          method: 'PUT',
          headers: myHeaders,
          body: JSON.stringify(values),
        };
        console.log(values);
        fetch(
          `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${id}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => console.log(result))
          .then(setpostdata(!postdata))
          .catch((error) => console.log('error', error));
  
        exitEditingMode(); //required to exit editing mode and close modal
      }
    };
  
    const handleCancelRowEdits = () => {
      setValidationErrors({});
    };
  
    const handleDeleteRow = useCallback(
      (row) => {
        //
        if (
          !window.confirm(`Are you sure you want to delete ${row.getValue('id')}`)
        ) {
          return;
        }
        console.log('deleted');
        var myHeaders = new Headers();
        myHeaders.append('apikey', apiKey);
        myHeaders.append('userid', user_id);
        var deletebody = { deleted_at: '12' };
        var requestOptions = {
          method: 'DELETE',
          headers: myHeaders,
          body: JSON.stringify(deletebody),
        };
  
        fetch(
          `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${row.getValue(
            'id'
          )}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => console.log(result))
          .then(setpostdata(!postdata))
          .catch((error) => console.log('error', error));
      },
      [tableData]
    );
  
    const getCommonEditTextFieldProps = useCallback(
      (cell) => {
        return {
          error: !!validationErrors[cell.id],
          helperText: validationErrors[cell.id],
          onBlur: (event) => {
            const isValid = validateRequired(event.target.value);
            if (!isValid) {
              //set validation error for cell if invalid
              setValidationErrors({
                ...validationErrors,
                [cell.id]: `${cell.column.columnDef.header} is required`,
              });
            } else {
              //remove validation error for cell if valid
              delete validationErrors[cell.id];
              setValidationErrors({
                ...validationErrors,
              });
            }
          },
        };
      },
      [validationErrors]
    );
  
    const columns = useMemo(
      () => [
        {
          accessorKey: 'id',
          header: 'ID',
          enableColumnOrdering: true,
          enableEditing: true, //disable editing on this column
          enableSorting: true,
          size: 80,
          muiTableBodyCellProps: ({ cell }) => ({
            onClick: (event) => {
              navigate(`${cell.row.original.id}`)
              console.info(event, cell.row.original.id);
            },
            sx: {
              cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
          })
          
        },
        {
          accessorKey: 'izci_length',
          header: 'length',
  
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: 'izci_hw_group',
          header: 'hw_group',
  
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: 'izci_hw_cfg',
          header: 'hw_cfg',
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: 'izci_packet_ver',
          header: 'packet_ver',
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: 'izci_hw_ver',
          header: 'hw_ver',
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: 'izci_hw_rev',
          header: 'hw_rev',
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: 'izci_sw_ver',
          header: 'sw_ver',
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: 'produced_at',
          header: 'produced_at',
          enableEditing: false, //disable editing on this column
          size: 80,
        },
        {
          accessorKey: 'owner_id',
          header: 'owner_id',
          size: 80,
          muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
            ...getCommonEditTextFieldProps(cell),
          }),
        },
        {
          accessorKey: 'qr',
          header: 'qr',
          enableColumnOrdering: false,
          enableEditing: false, //disable editing on this column
          enableSorting: false,
          size: 80,
          Cell: ({ cell }) => (
            <>
              <QRCode
                id={cell.row.original.id}
                size={256}
                style={{
                  height: 'auto',
                  width: '70px',
                }}
                value={cell.row.original.id}
                viewBox={`0 0 256 256`}
              />
            </>
          ),
        },
      ],
      [getCommonEditTextFieldProps]
    );
  
    const downloadQRCode = (row) => {
      console.log('row : ', row.original.id);
      var svg = document.getElementById(row.original.id);
  
      //get svg source.
      var serializer = new XMLSerializer();
      var source = serializer.serializeToString(svg);
  
      //add name spaces.
      if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns="http://www.w3.org/2000/svg"'
        );
      }
      if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
        source = source.replace(
          /^<svg/,
          '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
        );
      }
  
      //add xml declaration
      source = '<?xml version="1.0" standalone="no"?>\r\n' + source;
  
      //convert svg source to URI data scheme.
      var url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);
  
      //set url value to a element's href attribute.
  
      //you can download svg file by right click menu.
      let downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = `${row.original.id}.svg`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
    function handleOpenInNew(row) {
      console.log(row.original.id);
      // navigate(row.original.id, '_blank');
      window.open(`/izci/${row.original.id}`, '_blank');
    }
    function handleOpenCalibration (row){
      navigate(`${row.original.id}/calibration`)
    }
    function formatDate(date) {
      var mdate = new Date(date);
      mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
      return `       ${mdate.getHours()}:${
        mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
      }
       ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
      `;
    }
    function fetchLast(data) {
      fetch(
        `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${data.id}&type=last&izcilength=${data.izci_length}`,
        {
          method: 'GET',
        }
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          const resultfilter = JSON.parse(result.body);
          Object.keys(resultfilter).map((e, i) => {
            data[e] = resultfilter[e];
          });
        })
        .catch((err) => {
          console.log('lasdatafetcherr : ', err);
        });
      const original = [
        'coords',
        'izci_hw_group',
        'izci_length',
        'qr',
        'created_at',
        'izci_packet_ver',
        'izci_hw_cfg',
        'izci_hw_rev',
        'izci_hw_ver',
        'owner_id',
        'produced_at',
        'izci_sw_ver',
        'id',
        'type',
        'time',
      ];
      return (
        <div className='flex flex-row  '>
          <p className='text-xl font-bold text-blue-600 mr-5 items-center'>
            <p>last measure </p> <p>{formatDate(data.time)}</p>
          </p>
          {Object.keys(data).map((e, i) => {
            if (!original.includes(e)) {
              return (
                <div
                  key={Math.random()}
                  className='flex flex-col justify-center items-center bg-slate-200 '
                >
                  <p className='text-lg font-bold text-gray-600 px-5'>{e}</p>
                  <p className='text-xl font-bold bg-white w-full px-5'>
                    {Number(data[e]).toFixed(1)}
                  </p>
                </div>
              );
            }
          })}
        </div>
      );
    }
    return (
      <>
        <MaterialReactTable
          columns={columns}
          data={tableData}
          editingMode='modal' //default
          enableColumnOrdering
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          renderDetailPanel={({ row }) => fetchLast(row.original)}
          enableRowNumbers
          enableRowActions
          enableRowSelection
          enableGrouping
    // initialState={{ grouping: ['id'] }}
    state={{
      
      isLoading: loading
    }}
          renderRowActionMenuItems={({ closeMenu, table, row }) => [
            <MenuItem
              key={0}
              onClick={() => {
                table.setEditingRow(row);
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              Edit
            </MenuItem>,
            <MenuItem
              key={1}
              onClick={() => {
                handleDeleteRow(row);
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              Delete
            </MenuItem>,
            
            <MenuItem
              key={2}
              onClick={() => {
                downloadQRCode(row);
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Download />
              </ListItemIcon>
              Download QR Code
            </MenuItem>,
            <MenuItem
            key={3}
            onClick={() => {
              handleOpenCalibration(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <OpenInNew />
            </ListItemIcon>
            Calibration
          </MenuItem>,
          ]}
          renderTopToolbarCustomActions={() => (
            <Button
              color='primary'
              onClick={() => setCreateModalOpen(true)}
              variant='contained'
            >
              Create New ToF
            </Button>
          )}
        />
        <CreateNewAccountModal
          columns={columns}
          open={createModalOpen}
          onClose={() => setCreateModalOpen(false)}
          onSubmit={handleCreateNewRow}
        />
      </>
    );
  };
  
  //example of creating a mui dialog modal for creating new rows
  export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
    const [values, setValues] = useState(() =>
      columns.reduce((acc, column) => {
        acc[column.accessorKey ?? ''] = '';
        return acc;
      }, {})
    );
  
    const handleSubmit = () => {
      //put your validation logic here
      onSubmit(values);
      onClose();
    };
  
    return (
      <Dialog open={open}>
        <DialogTitle textAlign='center'>Create New İzci</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              sx={{
                width: '100%',
                minWidth: { xs: '300px', sm: '360px', md: '400px' },
                gap: '1.5rem',
              }}
            >
              {columns.map((column) =>
                column.enableEditing == false ? (
                  ''
                ) : (
                  <TextField
                    key={column.accessorKey}
                    label={column.header}
                    name={column.accessorKey}
                    onChange={(e) =>
                      setValues({ ...values, [e.target.name]: e.target.value })
                    }
                  />
                )
              )}
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: '1.25rem' }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button color='primary' onClick={handleSubmit} variant='contained'>
            Create New İzci
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  const validateRequired = (value) => !!value.length;
  
  export default ToF;
  