import React, {
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useState,
} from "react";
import MaterialReactTable from "material-react-table";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { AccountContext } from "../Account";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  ListItemIcon,
} from "@mui/material";
import { Delete, Edit, OpenInNew, Download } from "@mui/icons-material";
import ReactEcharts from "echarts-for-react";
const IzciCalibration = () => {
  const { apiKey, user_id } = useContext(AccountContext);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [calibrationData, setCalibrationData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [postdata, setpostdata] = useState(false);
  const [lastTime, setLastTime] = useState();
  const [tsdata, settsdata] = useState();
  const { id } = useParams();

  useEffect(() => {
    console.log("id :::", id);
    var myHeaders = new Headers();
    myHeaders.append("apikey", apiKey);
    myHeaders.append("userid", user_id);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const resultfilter = result.body;

        setTableData([resultfilter]);
        let calibre = [
          { ...resultfilter.boru40Dolu, name: "boru40dolu" },
          { ...resultfilter.boru40Bos, name: "boru40Bos" },
          { ...resultfilter.boru32Dolu, name: "boru32dolu" },
          { ...resultfilter.boru32Bos, name: "boru32Bos" },
          { ...resultfilter.alkolDolu, name: "alkolDolu" },
          { ...resultfilter.alkolBos, name: "alkolBos" },
          { ...resultfilter.havaDolu, name: "havaDolu" },
          { ...resultfilter.havaBos, name: "havaBos" },
          { ...resultfilter.suDolu, name: "suDolu" },
          { ...resultfilter.suBos, name: "suBos" },
        ];
        setCalibrationData(calibre);
        console.log("calibre", calibre);
        fetch(
          `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${resultfilter.id}&type=tabular&izcilength=${resultfilter.izci_length}`,
          {
            method: "GET",
          }
        )
          .then((response) => response.json())
          .then((result) => {
            var asd = JSON.stringify(result.body);
              console.log('calibration data : ', result.body);
            var obj = [];
            result.body.map((e) => {
              // console.log('aha bu e:', e);
              obj.push(JSON.parse(e));
            });
            //   console.log('obj:', obj);
            settsdata(obj);
          });
      })
      .then(() => setfull(true))
      .catch((error) => console.log("error", error));
    
        fetch(
            `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${id}`,
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              const resultfilter = result.body;
      
              setTableData([resultfilter]);
              let calibre = [
                { ...resultfilter.boru40Dolu, name: "boru40dolu" },
                { ...resultfilter.boru40Bos, name: "boru40Bos" },
                { ...resultfilter.boru32Dolu, name: "boru32dolu" },
                { ...resultfilter.boru32Bos, name: "boru32Bos" },
                { ...resultfilter.alkolDolu, name: "alkolDolu" },
                { ...resultfilter.alkolBos, name: "alkolBos" },
                { ...resultfilter.havaDolu, name: "havaDolu" },
                { ...resultfilter.havaBos, name: "havaBos" },
                { ...resultfilter.suDolu, name: "suDolu" },
                { ...resultfilter.suBos, name: "suBos" },
              ];
              setCalibrationData(calibre);
              console.log("calibre", calibre);
              fetch(
                `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${resultfilter.id}&type=tabular&izcilength=${resultfilter.izci_length}&calibration=sensorCalibration`,
                {
                  method: "GET",
                }
              )
                .then((response) => response.json())
                .then((result) => {
                  var asd = JSON.stringify(result.body);
                  //   console.log('new type data : ', result.body);
                  var obj = [];
                  result.body.map((e) => {
                    // console.log('aha bu e:', e);
                    obj.push(JSON.parse(e));
                  });
                  //   console.log('obj:', obj);
                  settsdata(obj);
                });
            })
            .then(() => setfull(true))
            .catch((error) => console.log("error", error));
        
      fetch(
        `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          const resultfilter = result.body;
          console.log("tabledata", resultfilter);
          setTableData([resultfilter]);
          fetch(
            `https://1nftg8vgd7.execute-api.eu-west-1.amazonaws.com/test?id=${resultfilter.id}&type=tabular&izcilength=${resultfilter.izci_length}`,
            {
              method: "GET",
            }
          )
            .then((response) => response.json())
            .then((result) => {
              var asd = JSON.stringify(result.body);
              console.log('calibration data : ', result.body);
              var obj = [];
              result.body.map((e) => {
                //   console.log('aha bu e:', e);
                obj.push(JSON.parse(e));
              });
              setLastTime(obj[0]?.time ? obj[0].time : "");
              console.log("obj:", obj);
              settsdata(obj);
            });
        })
        .then(() => setfull(true))
        .catch((error) => console.log("error", error));
    
    
  }, [postdata]);

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var updatedValues = values;
      var id = values.id;
      delete updatedValues.id;
      delete updatedValues.produced_at;

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: JSON.stringify(values),
      };
      console.log(values);
      fetch(
        `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log("error", error));

      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row) => {
      //
      if (
        !window.confirm(`Are you sure you want to delete ${row.getValue("id")}`)
      ) {
        return;
      }
      console.log("deleted");
      var myHeaders = new Headers();
      myHeaders.append("apikey", apiKey);
      myHeaders.append("userid", user_id);
      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
      };

      fetch(
        `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${row.getValue(
          "id"
        )}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log("error", error));
    },
    [tableData]
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );
  function setLast(time) {
    var mdate = new Date(time);
    mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
    //   console.log('time', mdate.getTime());

    return `       ${mdate.getHours()}:${
      mdate.getMinutes() < 10 ? `0${mdate.getMinutes()}` : mdate.getMinutes()
    }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
    `;
  }
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        enableClickToCopy: true,
        size: 80,
      },
      {
        accessorKey: "izci_length",
        header: "length",
        enableEditing: true, //disable editing on this column
        size: 80,
      },
      {
        accessorKey: "izci_hw_group",
        header: "hw_group",
        enableEditing: true, //disable editing on this column
        size: 80,
      },

      {
        accessorKey: "izci_hw_cfg",
        header: "hw_cfg",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "izci_packet_ver",
        header: "packet_ver",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "izci_hw_ver",
        header: "hw_ver",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "izci_hw_rev",
        header: "hw_rev",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "izci_sw_ver",
        header: "sw_ver",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },

      {
        accessorKey: "produced_at",
        header: "produced_at",
        enableEditing: false, //disable editing on this column
        size: 80,
      },
      {
        accessorKey: "owner_id",
        header: "owner_id",
        size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "qr",
        header: "qr",
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 80,
        Cell: ({ cell }) => (
          <>
            <QRCode
              id={cell.row.original.id}
              size={256}
              style={{
                height: "auto",
                width: "70px",
              }}
              value={cell.row.original.id}
              viewBox={`0 0 256 256`}
            />
          </>
        ),
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const columns90 = useMemo(
    () => [
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(originalRow.time);
          mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
          console.log("originalRow.time", mdate.getTime());
          originalRow.time = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
            `;
        },
        header: "time",
        size: 80,
      },
      
      {
        accessorKey: "at",
        header: "at",
        size: 80,
      },
      
      
      {
        accessorKey: "sf5",
        header: "sf5",
        size: 80,
      },
      {
        accessorKey: "sf15",
        header: "sf15",
        size: 80,
      },

      {
        accessorKey: "sf25",
        header: "sf25",
        size: 80,
      },
      {
        accessorKey: "sf35",
        header: "sf35",
        size: 80,
      },
      {
        accessorKey: "sf45",
        header: "sf45",
        size: 80,
      },
      {
        accessorKey: "sf55",
        header: "sf55",
        size: 80,
      },
      {
        accessorKey: "sf65",
        header: "sf65",
        size: 80,
      },
      {
        accessorKey: "sf75",
        header: "sf75",
        size: 80,
      },
      {
        accessorKey: "sf85",
        header: "sf85",
        size: 80,
      },
      {
        accessorKey: "st5",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "st15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "st25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "st35",
        header: "st35",
        size: 80,
      },
      {
        accessorKey: "st45",
        header: "st45",
        size: 80,
      },
      {
        accessorKey: "st55",
        header: "st55",
        size: 80,
      },
      {
        accessorKey: "st65",
        header: "st65",
        size: 80,
      },
      {
        accessorKey: "st75",
        header: "st75",
        size: 80,
      },
      {
        accessorKey: "st85",
        header: "st85",
        size: 80,
      },
      {
        accessorKey: "sf_sd5",
        header: "sf_sd5",
        size: 80,
      },
      {
        accessorKey: "sf_sd15",
        header: "sf_sd15",
        size: 80,
      },

      {
        accessorKey: "sf_sd25",
        header: "sf_sd25",
        size: 80,
      },
      {
        accessorKey: "sf_sd35",
        header: "sf_sd35",
        size: 80,
      },
      {
        accessorKey: "sf_sd45",
        header: "sf_sd45",
        size: 80,
      },
      {
        accessorKey: "sf_sd55",
        header: "sf_sd55",
        size: 80,
      },
      {
        accessorKey: "sf_sd65",
        header: "sf_sd65",
        size: 80,
      },
      {
        accessorKey: "sf_sd75",
        header: "sf_sd75",
        size: 80,
      },
      {
        accessorKey: "sf_sd85",
        header: "sf_sd85",
        size: 80,
      },
      {
        accessorKey: "sc5",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "sc15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "sc25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "sc35",
        header: "sc35",
        size: 80,
      },
      {
        accessorKey: "sc45",
        header: "sc45",
        size: 80,
      },
      {
        accessorKey: "sc55",
        header: "sc55",
        size: 80,
      },
      {
        accessorKey: "sc65",
        header: "sc65",
        size: 80,
      },
      {
        accessorKey: "sc75",
        header: "sc75",
        size: 80,
      },
      {
        accessorKey: "sc85",
        header: "sc85",
        size: 80,
      },
      {
        accessorKey: "vbus5",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "vbus15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "vbus25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "vbus35",
        header: "vbus35",
        size: 80,
      },
      {
        accessorKey: "vbus45",
        header: "vbus45",
        size: 80,
      },
      {
        accessorKey: "vbus55",
        header: "vbus55",
        size: 80,
      },
      {
        accessorKey: "vbus65",
        header: "vbus65",
        size: 80,
      },
      {
        accessorKey: "vbus75",
        header: "vbus75",
        size: 80,
      },
      {
        accessorKey: "vbus85",
        header: "vbus85",
        size: 80,
      },
      {
        accessorKey: "btv",
        header: "btv",
        size: 80,
      },
      {
        accessorKey: "tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "tc",
        header: "tc",
        size: 80,
      },
      {
        accessorKey: "error",
        header: "error",
        size: 80,
      },
      {
        accessorKey: "sm5",
        header: "sm5",
        size: 80,
      },
      {
        accessorKey: "sm15",
        header: "sm15",
        size: 80,
      },

      {
        accessorKey: "sm25",
        header: "sm25",
        size: 80,
      },
      {
        accessorKey: "sm35",
        header: "sm35",
        size: 80,
      },
      {
        accessorKey: "sm45",
        header: "sm45",
        size: 80,
      },
      {
        accessorKey: "sm55",
        header: "sm55",
        size: 80,
      },
      {
        accessorKey: "sm65",
        header: "sm65",
        size: 80,
      },
      {
        accessorKey: "sm75",
        header: "sm75",
        size: 80,
      },
      {
        accessorKey: "sm85",
        header: "sm85",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const columns60 = useMemo(
    () => [
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(originalRow.time);
          mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
          console.log("originalRow.time", mdate.getTime());
          originalRow.time = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
            `;
        },
        header: "time",
        size: 80,
      },
      
      {
        accessorKey: "at",
        header: "at",
        size: 80,
      },
      
      
      {
        accessorKey: "sf5",
        header: "sf5",
        size: 80,
      },
      {
        accessorKey: "sf15",
        header: "sf15",
        size: 80,
      },

      {
        accessorKey: "sf25",
        header: "sf25",
        size: 80,
      },
      {
        accessorKey: "sf35",
        header: "sf35",
        size: 80,
      },
      {
        accessorKey: "sf45",
        header: "sf45",
        size: 80,
      },
      {
        accessorKey: "sf55",
        header: "sf55",
        size: 80,
      },
      {
        accessorKey: "st5",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "st15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "st25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "st35",
        header: "st35",
        size: 80,
      },
      {
        accessorKey: "st45",
        header: "st45",
        size: 80,
      },
      {
        accessorKey: "st55",
        header: "st55",
        size: 80,
      },
      {
        accessorKey: "sf_sd5",
        header: "sf_sd5",
        size: 80,
      },
      {
        accessorKey: "sf_sd15",
        header: "sf_sd15",
        size: 80,
      },

      {
        accessorKey: "sf_sd25",
        header: "sf_sd25",
        size: 80,
      },
      {
        accessorKey: "sf_sd35",
        header: "sf_sd35",
        size: 80,
      },
      {
        accessorKey: "sf_sd45",
        header: "sf_sd45",
        size: 80,
      },
      {
        accessorKey: "sf_sd55",
        header: "sf_sd55",
        size: 80,
      },
      {
        accessorKey: "sc5",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "sc15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "sc25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "sc35",
        header: "sc35",
        size: 80,
      },
      {
        accessorKey: "sc45",
        header: "sc45",
        size: 80,
      },
      {
        accessorKey: "sc55",
        header: "sc55",
        size: 80,
      },
      {
        accessorKey: "vbus5",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "vbus15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "vbus25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "vbus35",
        header: "vbus35",
        size: 80,
      },
      {
        accessorKey: "vbus45",
        header: "vbus45",
        size: 80,
      },
      {
        accessorKey: "vbus55",
        header: "vbus55",
        size: 80,
      },
      {
        accessorKey: "btv",
        header: "btv",
        size: 80,
      },
      {
        accessorKey: "tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "tc",
        header: "tc",
        size: 80,
      },
      {
        accessorKey: "error",
        header: "error",
        size: 80,
      },
      {
        accessorKey: "sm5",
        header: "sm5",
        size: 80,
      },
      {
        accessorKey: "sm15",
        header: "sm15",
        size: 80,
      },

      {
        accessorKey: "sm25",
        header: "sm25",
        size: 80,
      },
      {
        accessorKey: "sm35",
        header: "sm35",
        size: 80,
      },
      {
        accessorKey: "sm45",
        header: "sm45",
        size: 80,
      },
      {
        accessorKey: "sm55",
        header: "sm55",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const columns30 = useMemo(
    () => [
        {
            accessorFn: (originalRow) => {
              var mdate = new Date(originalRow.time);
              mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
              console.log("originalRow.time", mdate.getTime());
              originalRow.time = mdate;
              return `       ${mdate.getHours()}:${
                mdate.getMinutes() < 10
                  ? `0${mdate.getMinutes()}`
                  : mdate.getMinutes()
              }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
                `;
            },
            header: "time",
            size: 80,
          },
      
      {
        accessorKey: "at",
        header: "at",
        size: 80,
      },
      
      
      {
        accessorKey: "sf5",
        header: "sf5",
        size: 80,
      },
      {
        accessorKey: "sf15",
        header: "sf15",
        size: 80,
      },

      {
        accessorKey: "sf25",
        header: "sf25",
        size: 80,
      },
      {
        accessorKey: "st5",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "st15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "st25",
        header: "st25",
        size: 80,
      },


      {
        accessorKey: "sf_sd5",
        header: "sf_sd5",
        size: 80,
      },
      {
        accessorKey: "sf_sd15",
        header: "sf_sd15",
        size: 80,
      },

      {
        accessorKey: "sf_sd25",
        header: "sf_sd25",
        size: 80,
      },

      {
        accessorKey: "sc5",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "sc15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "sc25",
        header: "sc25",
        size: 80,
      },

      {
        accessorKey: "vbus5",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "vbus15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "vbus25",
        header: "vbus25",
        size: 80,
      },

      {
        accessorKey: "btv",
        header: "btv",
        size: 80,
      },
      {
        accessorKey: "tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "tc",
        header: "tc",
        size: 80,
      },
      {
        accessorKey: "error",
        header: "error",
        size: 80,
      },
      {
        accessorKey: "sm5",
        header: "sm5",
        size: 80,
      },
      {
        accessorKey: "sm15",
        header: "sm15",
        size: 80,
      },

      {
        accessorKey: "sm25",
        header: "sm25",
        size: 80,
      },

    ],
    [getCommonEditTextFieldProps]
  );
  const calibrationColumns90 = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "name",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(originalRow.time);
          
          console.log("originalRow.time", mdate.getTime());
          originalRow.time = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
              `;
        },
        header: "time",
        size: 80,
      },
     
      {
        accessorKey: "at",
        header: "at",
        size: 80,
      },
      
      
      {
        accessorKey: "sf5",
        header: "sf5",
        size: 80,
      },
      {
        accessorKey: "sf15",
        header: "sf15",
        size: 80,
      },

      {
        accessorKey: "sf25",
        header: "sf25",
        size: 80,
      },
      {
        accessorKey: "sf35",
        header: "sf35",
        size: 80,
      },
      {
        accessorKey: "sf45",
        header: "sf45",
        size: 80,
      },
      {
        accessorKey: "sf55",
        header: "sf55",
        size: 80,
      },
      {
        accessorKey: "sf65",
        header: "sf65",
        size: 80,
      },
      {
        accessorKey: "sf75",
        header: "sf75",
        size: 80,
      },
      {
        accessorKey: "sf85",
        header: "sf85",
        size: 80,
      },
      {
        accessorKey: "st5",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "st15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "st25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "st35",
        header: "st35",
        size: 80,
      },
      {
        accessorKey: "st45",
        header: "st45",
        size: 80,
      },
      {
        accessorKey: "st55",
        header: "st55",
        size: 80,
      },
      {
        accessorKey: "st65",
        header: "st65",
        size: 80,
      },
      {
        accessorKey: "st75",
        header: "st75",
        size: 80,
      },
      {
        accessorKey: "st85",
        header: "st85",
        size: 80,
      },
      {
        accessorKey: "sf_sd5",
        header: "sf_sd5",
        size: 80,
      },
      {
        accessorKey: "sf_sd15",
        header: "sf_sd15",
        size: 80,
      },

      {
        accessorKey: "sf_sd25",
        header: "sf_sd25",
        size: 80,
      },
      {
        accessorKey: "sf_sd35",
        header: "sf_sd35",
        size: 80,
      },
      {
        accessorKey: "sf_sd45",
        header: "sf_sd45",
        size: 80,
      },
      {
        accessorKey: "sf_sd55",
        header: "sf_sd55",
        size: 80,
      },
      {
        accessorKey: "sf_sd65",
        header: "sf_sd65",
        size: 80,
      },
      {
        accessorKey: "sf_sd75",
        header: "sf_sd75",
        size: 80,
      },
      {
        accessorKey: "sf_sd85",
        header: "sf_sd85",
        size: 80,
      },
      {
        accessorKey: "sc5",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "sc15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "sc25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "sc35",
        header: "sc35",
        size: 80,
      },
      {
        accessorKey: "sc45",
        header: "sc45",
        size: 80,
      },
      {
        accessorKey: "sc55",
        header: "sc55",
        size: 80,
      },
      {
        accessorKey: "sc65",
        header: "sc65",
        size: 80,
      },
      {
        accessorKey: "sc75",
        header: "sc75",
        size: 80,
      },
      {
        accessorKey: "sc85",
        header: "sc85",
        size: 80,
      },
      {
        accessorKey: "vbus5",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "vbus15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "vbus25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "vbus35",
        header: "vbus35",
        size: 80,
      },
      {
        accessorKey: "vbus45",
        header: "vbus45",
        size: 80,
      },
      {
        accessorKey: "vbus55",
        header: "vbus55",
        size: 80,
      },
      {
        accessorKey: "vbus65",
        header: "vbus65",
        size: 80,
      },
      {
        accessorKey: "vbus75",
        header: "vbus75",
        size: 80,
      },
      {
        accessorKey: "vbus85",
        header: "vbus85",
        size: 80,
      },
      {
        accessorKey: "btv",
        header: "btv",
        size: 80,
      },
      {
        accessorKey: "tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "tc",
        header: "tc",
        size: 80,
      },
      {
        accessorKey: "error",
        header: "error",
        size: 80,
      },
      {
        accessorKey: "sm5",
        header: "sm5",
        size: 80,
      },
      {
        accessorKey: "sm15",
        header: "sm15",
        size: 80,
      },

      {
        accessorKey: "sm25",
        header: "sm25",
        size: 80,
      },
      {
        accessorKey: "sm35",
        header: "sm35",
        size: 80,
      },
      {
        accessorKey: "sm45",
        header: "sm45",
        size: 80,
      },
      {
        accessorKey: "sm55",
        header: "sm55",
        size: 80,
      },
      {
        accessorKey: "sm65",
        header: "sm65",
        size: 80,
      },
      {
        accessorKey: "sm75",
        header: "sm75",
        size: 80,
      },
      {
        accessorKey: "sm85",
        header: "sm85",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const calibaritionColumns60 = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "name",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(originalRow.time);
          
          console.log("originalRow.time", mdate.getTime());
          originalRow.time = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
              `;
        },
        header: "time",
        size: 80,
      },
      
      {
        accessorKey: "at",
        header: "at",
        size: 80,
      },
      
      
      {
        accessorKey: "sf5",
        header: "sf5",
        size: 80,
      },
      {
        accessorKey: "sf15",
        header: "sf15",
        size: 80,
      },

      {
        accessorKey: "sf25",
        header: "sf25",
        size: 80,
      },
      {
        accessorKey: "sf35",
        header: "sf35",
        size: 80,
      },
      {
        accessorKey: "sf45",
        header: "sf45",
        size: 80,
      },
      {
        accessorKey: "sf55",
        header: "sf55",
        size: 80,
      },
      {
        accessorKey: "st5",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "st15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "st25",
        header: "st25",
        size: 80,
      },
      {
        accessorKey: "st35",
        header: "st35",
        size: 80,
      },
      {
        accessorKey: "st45",
        header: "st45",
        size: 80,
      },
      {
        accessorKey: "st55",
        header: "st55",
        size: 80,
      },
      {
        accessorKey: "sf_sd5",
        header: "sf_sd5",
        size: 80,
      },
      {
        accessorKey: "sf_sd15",
        header: "sf_sd15",
        size: 80,
      },

      {
        accessorKey: "sf_sd25",
        header: "sf_sd25",
        size: 80,
      },
      {
        accessorKey: "sf_sd35",
        header: "sf_sd35",
        size: 80,
      },
      {
        accessorKey: "sf_sd45",
        header: "sf_sd45",
        size: 80,
      },
      {
        accessorKey: "sf_sd55",
        header: "sf_sd55",
        size: 80,
      },
      {
        accessorKey: "sc5",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "sc15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "sc25",
        header: "sc25",
        size: 80,
      },
      {
        accessorKey: "sc35",
        header: "sc35",
        size: 80,
      },
      {
        accessorKey: "sc45",
        header: "sc45",
        size: 80,
      },
      {
        accessorKey: "sc55",
        header: "sc55",
        size: 80,
      },
      {
        accessorKey: "vbus5",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "vbus15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "vbus25",
        header: "vbus25",
        size: 80,
      },
      {
        accessorKey: "vbus35",
        header: "vbus35",
        size: 80,
      },
      {
        accessorKey: "vbus45",
        header: "vbus45",
        size: 80,
      },
      {
        accessorKey: "vbus55",
        header: "vbus55",
        size: 80,
      },
      {
        accessorKey: "btv",
        header: "btv",
        size: 80,
      },
      {
        accessorKey: "tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "tc",
        header: "tc",
        size: 80,
      },
      {
        accessorKey: "error",
        header: "error",
        size: 80,
      },
      {
        accessorKey: "sm5",
        header: "sm5",
        size: 80,
      },
      {
        accessorKey: "sm15",
        header: "sm15",
        size: 80,
      },

      {
        accessorKey: "sm25",
        header: "sm25",
        size: 80,
      },
      {
        accessorKey: "sm35",
        header: "sm35",
        size: 80,
      },
      {
        accessorKey: "sm45",
        header: "sm45",
        size: 80,
      },
      {
        accessorKey: "sm55",
        header: "sm55",
        size: 80,
      },
    ],
    [getCommonEditTextFieldProps]
  );
  const calibrationColumns30 = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "name",
        size: 80,
      },
      {
        accessorFn: (originalRow) => {
          var mdate = new Date(originalRow.time);
          
          console.log("originalRow.time", mdate.getTime());
          originalRow.time = mdate;
          return `       ${mdate.getHours()}:${
            mdate.getMinutes() < 10
              ? `0${mdate.getMinutes()}`
              : mdate.getMinutes()
          }  ${mdate.getDate()}.${mdate.getMonth() + 1}.${mdate.getFullYear()}
              `;
        },
        header: "time",
        size: 80,
      },
      
      {
        accessorKey: "at",
        header: "at",
        size: 80,
      },
      
      

      {
        accessorKey: "sf5",
        header: "sf5",
        size: 80,
      },
      {
        accessorKey: "sf15",
        header: "sf15",
        size: 80,
      },

      {
        accessorKey: "sf25",
        header: "sf25",
        size: 80,
      },
      {
        accessorKey: "st5",
        header: "st5",
        size: 80,
      },
      {
        accessorKey: "st15",
        header: "st15",
        size: 80,
      },

      {
        accessorKey: "st25",
        header: "st25",
        size: 80,
      },

      {
        accessorKey: "sf_sd5",
        header: "sf_sd5",
        size: 80,
      },
      {
        accessorKey: "sf_sd15",
        header: "sf_sd15",
        size: 80,
      },

      {
        accessorKey: "sf_sd25",
        header: "sf_sd25",
        size: 80,
      },

      {
        accessorKey: "sc5",
        header: "sc5",
        size: 80,
      },
      {
        accessorKey: "sc15",
        header: "sc15",
        size: 80,
      },

      {
        accessorKey: "sc25",
        header: "sc25",
        size: 80,
      },

      {
        accessorKey: "vbus5",
        header: "vbus5",
        size: 80,
      },
      {
        accessorKey: "vbus15",
        header: "vbus15",
        size: 80,
      },

      {
        accessorKey: "vbus25",
        header: "vbus25",
        size: 80,
      },

      {
        accessorKey: "btv",
        header: "btv",
        size: 80,
      },
      {
        accessorKey: "tbv",
        header: "tbv",
        size: 80,
      },
      {
        accessorKey: "tc",
        header: "tc",
        size: 80,
      },
      {
        accessorKey: "error",
        header: "error",
        size: 80,
      },
      {
        accessorKey: "sm5",
        header: "sm5",
        size: 80,
      },
      {
        accessorKey: "sm15",
        header: "sm15",
        size: 80,
      },

      {
        accessorKey: "sm25",
        header: "sm25",
        size: 80,
      },

    ],
    [getCommonEditTextFieldProps]
  );
  const downloadQRCode = (row) => {
    console.log("row : ", row.original.id);
    var svg = document.getElementById(row.original.id);

    //get svg source.
    var serializer = new XMLSerializer();
    var source = serializer.serializeToString(svg);

    //add name spaces.
    if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns="http://www.w3.org/2000/svg"'
      );
    }
    if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
      );
    }

    //add xml declaration
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

    //convert svg source to URI data scheme.
    var url = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(source);

    //set url value to a element's href attribute.

    //you can download svg file by right click menu.
    let downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = `${row.original.id}.svg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const [full, setfull] = useState(false);
  const [loading, setLoading] = useState(false);
  const [time, settime] = useState([]);
  const [value, setvalue] = useState([]);
  const [max, setmax] = useState();
  const [min, setmin] = useState();

  function yaz(attrname) {
    var time = [];
    var value = [];
    var lasttime;
    tsdata?.map((data) => {
      if (Date.parse(data.time) - lasttime < -7200000) {
        value.push([Date.parse(data.time) + 3200000, null]);
      }
      var mdate = new Date(data.time);
      mdate.setMinutes(mdate.getMinutes() - mdate.getTimezoneOffset());
      value.push([mdate, Number(data[attrname]).toFixed(2)]);

      lasttime = Date.parse(data.time);
    });
    return value;
  }
  const handleCalibration = async ( values ) => {
   
      var myHeaders = new Headers();
      myHeaders.append('apikey', apiKey);
      myHeaders.append('userid', user_id);
      var id = values.id;

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(values),
      };
      console.log("calibre tableData[0]",tableData[0].id)
      console.log("values calibre",values);
      fetch(
        `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/izci/${tableData[0].id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log('error', error));

    
  };
  return (
    <>
      <h1 className="text-4xl font-bold">Calibration Page</h1>
      <MaterialReactTable
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        enableRowNumbers
        enableRowActions
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enableGlobalFilter={false}
        enableHiding={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableColumnActions={false}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        renderRowActionMenuItems={({ closeMenu, table, row }) => [
          <MenuItem
            key={0}
            onClick={() => {
              table.setEditingRow(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            Edit
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() => {
              handleDeleteRow(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            Delete
          </MenuItem>,

          <MenuItem
            key={3}
            onClick={() => {
              downloadQRCode(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Download />
            </ListItemIcon>
            Download QR Code
          </MenuItem>,
        ]}
      />
      
          <div className="mb-12">
            {tableData[0] ? <MaterialReactTable
              columns={
                tableData[0].izci_length && tableData[0].izci_length == 60
                  ? calibaritionColumns60
                  : tableData[0].izci_length && tableData[0].izci_length == 90
                  ? calibrationColumns90
                  : tableData[0].izci_length && tableData[0].izci_length == 30
                  ? calibrationColumns30
                  : columns
              }
              data={calibrationData}
              editingMode="modal" //default
              enableFullScreenToggle={false}
              enableDensityToggle={false}
              enableGlobalFilter={false}
              enableHiding={false}
              enableColumnFilters={false}
              enablePagination={false}
              enableColumnActions={false}
              enableBottomToolbar={false}
              enableTopToolbar={false}
              
            />:""}
          </div>
        
      <>
        <h1 className="text-4xl m-5 flex flex-row gap-3 ">
          Measurements{" "}
          <p className="flex items-end text-xl text-blue-600">
            last measure: {tsdata && setLast(tsdata[0]?.time? tsdata[0]?.time : "")}
          </p>
        </h1>
        {tsdata &&
          (full == true ? (
            <div className="mb-12">
              <MaterialReactTable
                columns={
                  tableData[0].izci_length == 60
                    ? columns60
                    : tableData[0].izci_length == 90
                    ? columns90
                    : columns30
                }
                data={tsdata}
                editingMode="modal" //default
                enableRowNumbers
                enableRowActions
                enableFullScreenToggle={false}
                enableDensityToggle={false}
                enableGlobalFilter={false}
                enableHiding={false}
                enableColumnFilters={false}
                enablePagination={true}
                enableColumnActions={false}
                enableBottomToolbar={true}
                enableTopToolbar={false}
                renderRowActionMenuItems={({ closeMenu, table, row }) => [
                    <MenuItem
                      key={0}
                      onClick={() => {
                        console.log("row", row);
                        handleCalibration({boru40Dolu:row.original})
                        closeMenu();
                      }}
                      sx={{ m: 0 }}
                    >
                      <ListItemIcon>
                        <Edit />
                      </ListItemIcon>
                      Set Boru 40 Dolu
                    </MenuItem>,
                    <MenuItem
                    key={1}
                    onClick={() => {
                      console.log("row", row);
                      handleCalibration({boru40Bos:row.original})
                      closeMenu();
                    }}
                    sx={{ m: 0 }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    Set Boru 40 Boş
                  </MenuItem>,
                  <MenuItem
                  key={2}
                  onClick={() => {
                    console.log("row", row);
                    handleCalibration({boru32Dolu:row.original})
                    closeMenu();
                  }}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <Edit />
                  </ListItemIcon>
                  Set Boru 32 Dolu
                </MenuItem>,
                <MenuItem
                key={3}
                onClick={() => {
                  console.log("row", row);
                  handleCalibration({boru32Bos:row.original})
                  closeMenu();
                }}
                sx={{ m: 0 }}
              >
                <ListItemIcon>
                  <Edit />
                </ListItemIcon>
                Set Boru 32 Boş
              </MenuItem>,
              <MenuItem
              key={4}
              onClick={() => {
                console.log("row", row);
                handleCalibration({alkolDolu:row.original})
                closeMenu();
              }}
              sx={{ m: 0 }}
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              Set Alkol Dolu
            </MenuItem>,
            <MenuItem
            key={5}
            onClick={() => {
              console.log("row", row);
              handleCalibration({alkolBos:row.original})
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            Set Alkol Boş
          </MenuItem>,
          <MenuItem
          key={6}
          onClick={() => {
            console.log("row", row);
            handleCalibration({havaDolu:row.original})
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Set Hava Dolu
        </MenuItem>,
        <MenuItem
        key={7}
        onClick={() => {
          console.log("row", row);
          handleCalibration({havaBos:row.original})
          closeMenu();
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        Set Hava Boş
      </MenuItem>,
          <MenuItem
          key={8}
          onClick={() => {
            console.log("row", row);
            handleCalibration({suDolu:row.original})
            
            closeMenu();
          }}
          sx={{ m: 0 }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          Set Su Dolu
        </MenuItem>,
        <MenuItem
        key={9}
        onClick={() => {
          console.log("row", row);
          handleCalibration({suBos:row.original})
          
          closeMenu();
          
        }}
        sx={{ m: 0 }}
      >
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        Set Su Boş
      </MenuItem>,
      <MenuItem
      key={10}
      onClick={() => {
        console.log("row", row);
        handleCalibration({suBos:"",suDolu:"",havaBos:"",havaDolu:"",boru32Bos:"",boru32Dolu:"",boru40Bos:"",boru40Dolu:"",alkolBos:"",alkolDolu:""})
        closeMenu();
      }}
      sx={{ m: 0 }}
    >
      <ListItemIcon>
        <Edit />
      </ListItemIcon>
      Clear All Calibration
    </MenuItem>,
                  ]}
              />
            </div>
          ) : (
            <div className=" flex flex-row flex-wrap gap-5 ml-5 mr-5 ">
              <div className="bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10"></div>
              <div className="bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10"></div>
              <div className="bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10"></div>
              <div className="bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10"></div>
            </div>
          ))}
      </>
    </>
  );
};

const validateRequired = (value) => !!value.length;

export default IzciCalibration;
