import React, {
  useMemo,
  useEffect,
  useContext,
  useCallback,
  useState,
} from 'react';
import MaterialReactTable from 'material-react-table';
import QRCode from 'react-qr-code';
import { AccountContext } from '../Account';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  ListItemIcon,
} from '@mui/material';
import { Delete, Edit, OpenInNew, Download } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
const Ocak = () => {
  const { apiKey, user_id,userMail } = useContext(AccountContext);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [postdata, setpostdata] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append('apikey', apiKey);
    myHeaders.append('userid', user_id);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch(
      'https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/ocak',
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result.body.filter((obj) => !obj.deleted_at));
        const resultfilter = result.body.filter((obj) => !obj.deleted_at);
        const resultsort = resultfilter.sort(function (a, b) {
          return b.produced_at - a.produced_at;
        });
        setTableData(resultsort);
      })
      .then(setLoading(false))
      .catch((error) => console.log('error', error));
  }, [postdata]);

  const handleCreateNewRow = (values) => {
    console.log('values: ', values);
    values.type = 'ocak';
    var myHeaders = new Headers();
    myHeaders.append('apikey', apiKey);
    myHeaders.append('userid', user_id);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(values),
    };

    fetch(
      'https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/ocak',
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result))
      .then(setpostdata(!postdata))
      .catch((error) => console.log('error', error));
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      var myHeaders = new Headers();
      myHeaders.append('apikey', apiKey);
      myHeaders.append('userid', user_id);
      var updatedValues = values;
      var id = values.id;
      delete updatedValues.id;
      delete updatedValues.produced_at;

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(values),
      };
      console.log(values);
      fetch(
        `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/ocak/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log('error', error));

      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row) => {
      //
      if (
        !window.confirm(`Are you sure you want to delete ${row.getValue('id')}`)
      ) {
        return;
      }
      console.log('deleted');
      var myHeaders = new Headers();
      myHeaders.append('apikey', apiKey);
      myHeaders.append('userid', user_id);
      const currentTime = Date.now().toString();
      var deletebody = { deleted_at: currentTime };
      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: JSON.stringify(deletebody),
      };

      fetch(
        `https://45yj9tu42k.execute-api.eu-west-1.amazonaws.com/Stage1/ocak/${row.getValue(
          'id'
        )}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .then(setpostdata(!postdata))
        .catch((error) => console.log('error', error));
    },
    [tableData]
  );

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );
const colObj = userMail.includes("@yt.com.tr") ? [{
  accessorKey: 'produced_at',
  header: 'produced_at',
  enableEditing: false, //disable editing on this column
  size: 80,
},
{
  accessorKey: 'ocak_hw_cfg',
  header: 'ocak_hw_cfg',

  muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
    ...getCommonEditTextFieldProps(cell),
  }),
},
{
  accessorKey: 'ocak_hw_no',
  header: 'ocak_hw_no',
  size: 80,
  muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
    ...getCommonEditTextFieldProps(cell),
  }),
},
{
  accessorKey: 'ocak_packet_ver',
  header: 'ocak_packet_ver',
  size: 80,
  muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
    ...getCommonEditTextFieldProps(cell),
  }),
},
{
  accessorKey: 'ocak_sw_ver',
  header: 'ocak_sw_ver',
  size: 80,
  muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
    ...getCommonEditTextFieldProps(cell),
  }),
},
{
  accessorKey: 'owner_id',
  header: 'owner_id',
  enableColumnOrdering: false,
  enableSorting: false,
  size: 80,
  muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
    ...getCommonEditTextFieldProps(cell),
  }),
},
{
  accessorKey: 'qr',
  header: 'qr',
  enableColumnOrdering: false,
  enableEditing: false, //disable editing on this column
  enableSorting: false,
  enableFiltering: false,
  size: 80,
  Cell: ({ cell }) => (
    <>
      <QRCode
        id={cell.row.original.id}
        size={256}
        style={{
          height: 'auto',
          maxWidth: '100%',
          width: '70px',
        }}
        value={cell.row.original.id}
        viewBox={`0 0 256 256`}
      />
    </>
  ),
},] : []
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 140,
        muiTableBodyCellProps: ({ cell }) => ({
          onClick: (event) => {
            navigate(`ocak/${cell.row.original.id}`)
            console.info(event, cell.row.original.id);
          },
          sx: {
            cursor: 'pointer', //you might want to change the cursor too when adding an onClick
          },
        })
      },
      ...colObj
    ],
    [getCommonEditTextFieldProps]
  );
  const downloadQRCode = (row) => {
    console.log('row : ', row.original.id);
    var svg = document.getElementById(row.original.id);

    //get svg source.
    var serializer = new XMLSerializer();
    var source = serializer.serializeToString(svg);

    //add name spaces.
    if (!source.match(/^<svg[^>]+xmlns="http\:\/\/www\.w3\.org\/2000\/svg"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns="http://www.w3.org/2000/svg"'
      );
    }
    if (!source.match(/^<svg[^>]+"http\:\/\/www\.w3\.org\/1999\/xlink"/)) {
      source = source.replace(
        /^<svg/,
        '<svg xmlns:xlink="http://www.w3.org/1999/xlink"'
      );
    }

    //add xml declaration
    source = '<?xml version="1.0" standalone="no"?>\r\n' + source;

    //convert svg source to URI data scheme.
    var url = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(source);

    //set url value to a element's href attribute.

    //you can download svg file by right click menu.
    let downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = `${row.original.id}.svg`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  return (
    <>
      {!loading ? <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
          },
        }}
        columns={columns}
        data={tableData}
        editingMode='modal' //default
        enableColumnOrdering
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        enableRowNumbers
        enableRowActions={userMail.includes('@yt.com.tr') ? true :false}
        enableRowSelection={userMail.includes('@yt.com.tr') ? true :false}
        enableTopToolbar={userMail.includes('@yt.com.tr') ? true :false}
        initialState={{ density: 'compact' }}
        state={{
    
          isLoading: loading
        }}
        renderRowActionMenuItems={({ closeMenu, table, row }) => [
          <MenuItem
            key={0}
            onClick={() => {
              table.setEditingRow(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            Edit
          </MenuItem>,
          <MenuItem
            key={1}
            onClick={() => {
              handleDeleteRow(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            Delete
          </MenuItem>,
        
          <MenuItem
            key={2}
            onClick={() => {
              downloadQRCode(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <Download />
            </ListItemIcon>
            Download QR Code
          </MenuItem>,
        ]}
        renderTopToolbarCustomActions={() => (
          <Button
            color='primary'
            onClick={() => setCreateModalOpen(true)}
            variant='contained'
          >
            Create New Ocak
          </Button>
        )}
      /> : <div className=' flex flex-row flex-wrap gap-5 ml-5 mr-5 '>
      <div className='bg-slate-200 m-auto border-t-transparent border-solid animate-spin  rounded-full border-blue-600 border-4 h-10 w-10'></div>
       </div>}
      <CreateNewAccountModal
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
    </>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ open, columns, onClose, onSubmit }) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = '';
      return acc;
    }, {})
  );

  const handleSubmit = () => {
    //put your validation logic here
    onSubmit(values);
    onClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle textAlign='center'>Create New Account</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px' },
              gap: '1.5rem',
            }}
          >
            {columns.map((column) =>
              column.enableEditing == false ? (
                ''
              ) : (
                <TextField
                  key={column.accessorKey}
                  label={column.header}
                  name={column.accessorKey}
                  onChange={(e) =>
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }
                />
              )
            )}
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color='primary' onClick={handleSubmit} variant='contained'>
          Create New Ocak
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const validateRequired = (value) => !!value.length;

export default Ocak;
