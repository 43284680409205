import React, { useState, useContext } from 'react';
import { AccountContext } from '../Account';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assests/suyabakan_logo_svg.png';
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [letterValidated, setLetterValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginControl, setLoginControl] = useState('');
  const navigate = useNavigate();
  const {
    authenticate,
    pwChangeRequire,
    changePassword,
    newPassword,
    setNewPassword,
    setpwChangeRequire,
  } = useContext(AccountContext);
  const onSubmit = (event) => {
    console.log("1email",email,"password",password);
    event.preventDefault();
    console.log("2email",email,"password",password);
    authenticate(email, password)
      .then((data) => {
        console.log('Logged in!', data);
        console.log('Logged in!', event);

        setLoading(false);
        if(data.accessToken){
            navigate('/')
        }
       
      })
      .catch((err) => {
        switch (err.code) {
          case 'UserNotConfirmedException':
            setLoginControl('UserNotConfirmedException');
            break;
          case 'NotAuthorizedException':
            setLoginControl('NotAuthorizedException');
            break;
          case 'InvalidParameterException':
            setLoginControl('InvalidParameterException');
            break;
        }
        console.log('catch e :', err);
        setLoading(false);
      });
  };
  const onPwSubmit = (event) => {
    console.log("3email",email,"password",password);
    event.preventDefault();
    console.log("4email",email,"password",password);
    console.log("event",event);
    if (letterValidated && numberValidated && lengthValidated) {
      console.log("email",email,"password",password);
      changePassword(email, password).then((data) => {
        setLoading(false);
        setTimeout(() => {
          navigate('/login');
          setpwChangeRequire(false);
        }, 1000);
      });
    } else {
      setLoading(false);
    }
  };

  const handlePasswordChange = (value) => {
    setNewPassword(value);
    const letter = new RegExp('(?=.*[A-z])');
    const number = new RegExp('(?=.*[0-9])');
    const length = new RegExp('(?=.{8,})');
    if (letter.test(value)) {
      setLetterValidated(true);
    } else {
      setLetterValidated(false);
    }

    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
  };

  return (
    <>
      {pwChangeRequire == true ? (
        <div className='font-mono bg-gray-100 my-auto'>
          <div className='container mx-auto my-auto '>
            <div className='flex justify-center px-6 my-6 '>
              <div className='w-full xl:w-3/4 lg:w-11/12 flex '>
                <div className='bg-hero  w-full h-auto bg-gray-100 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg'>
                  <img src={Logo} className=' block w-40 h-28 mx-auto mt-3 ' />
                </div>

                <div className='w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none'>
                  <img src={Logo} className=' mx-auto my-8 w-32 lg:hidden ' />
                  <h3 className='pt-4 text-2xl text-center'>
                    Welcome Back My Lord!
                  </h3>
                  <form
                    onSubmit={(e) => {
                      setLoading(true);
                      onPwSubmit(e);
                    }}
                    className='px-8 pt-6 pb-8 mb-4 bg-white rounded  '
                  >
                    <h1 className='font-bold text-gray-700 '>
                      Change Password
                    </h1>
                    <div className='mb-4 '>
                      <label
                        className='block mb-2 text-sm font-bold text-green-600'
                        htmlFor='email'
                      >
                        New Password
                      </label>
                      <input
                        value={newPassword}
                        onChange={(event) =>
                          handlePasswordChange(event.target.value)
                        }
                        className='w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline'
                        type='password'
                        placeholder='New Password...'
                      />
                      <div className='flex flex-row flex-wrap text-xs text-gray-500'>
                        <p
                          className={
                            letterValidated ? `text-green-500 font-bold` : ``
                          }
                        >
                          {' '}
                          *Contain a Letter&nbsp;
                        </p>
                        <p
                          className={
                            numberValidated ? `text-green-500 font-bold` : ``
                          }
                        >
                          and a Number,&nbsp;
                        </p>
                        <p
                          className={
                            lengthValidated ? `text-green-500 font-bold` : ``
                          }
                        >
                          least 8 Characters
                        </p>
                      </div>
                    </div>

                    <div className='mb-6 text-center '>
                      <button
                        className='w-full px-4 py-2 font-bold text-white bg-green-500 rounded-full hover:bg-green-700 focus:outline-none focus:shadow-outline'
                        type='submit'
                        disabled={loading ? true : false}
                      >
                        {loading == true ? (
                          <div className='  '>
                            <div className='m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-6 w-6'></div>
                          </div>
                        ) : (
                          ' Change Password'
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='font-mono my-auto '>
          <div className='container mx-auto my-auto'>
            <div className='flex justify-center px-6 '>
              <div className='w-full xl:w-3/4 lg:w-11/12 flex'>
                <div className='bg-hero2 w-full h-auto bg-gray-100 hidden lg:block lg:w-1/2 bg-cover rounded-l-lg'>
                  <img src={Logo} className=' block w-40 h-28 mx-auto mt-3 ' />
                </div>

                <div className='w-full lg:w-1/2 bg-white p-5 rounded-lg lg:rounded-l-none'>
                  <img src={Logo} className=' mx-auto my-8 w-32 lg:hidden ' />
                  <h3 className='pt-4 text-2xl text-center'>
                    Welcome Back My Lord!
                  </h3>
                  <form
                    onSubmit={(e) => {
                      setLoading(true);

                      onSubmit(e);
                    }}
                    className='px-8 pt-6 pb-8 mb-4 bg-white rounded'
                  >
                    <div className='mb-4'>
                      <label
                        className='block mb-2 text-sm font-bold text-gray-700'
                        htmlFor='username'
                      >
                        Username
                      </label>
                      <input
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        className='w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline'
                        type='text'
                        placeholder='Username'
                      />
                    </div>
                    <div className='mb-4'>
                      <label
                        className='block mb-2 text-sm font-bold text-gray-700'
                        htmlFor='password'
                      >
                        Password
                      </label>
                      <input
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        className='w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border  rounded shadow appearance-none focus:outline-none focus:shadow-outline'
                        type='password'
                        placeholder='********'
                      />
                    </div>
                    <div className='mb-6 text-center'>
                      {loginControl == 'NotAuthorizedException' ? (
                        <p className='text-sm text-red-600'>
                          Incorrect username or password.
                        </p>
                      ) : loginControl == 'UserNotConfirmedException' ? (
                        <p className='text-sm text-red-600'>
                          Account is not confirmed. Check your email and verify
                          your account.
                        </p>
                      ) : (
                        loginControl == 'InvalidParameterException' && (
                          <p className='text-sm text-red-600'>
                            Email and password cannot be empty
                          </p>
                        )
                      )}
                      <button
                        className='w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline'
                        type='submit'
                        disabled={loading ? true : false}
                      >
                        {loading == true ? (
                          <div className='  bg-opacity-5 '>
                            <div className='m-auto border-t-transparent border-solid animate-spin  rounded-full border-white border-4 h-6 w-6'></div>
                          </div>
                        ) : (
                          'Login'
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
